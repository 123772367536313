import React from 'react';
import { Helmet } from 'react-helmet';
import './App.css';
import ResponsiveAppBar from './components/ResponsiveAppBar';

function PageWrapper({ children }) {
  return (
    <div className='App'>
      <Helmet>
        <title>Encuentro de Vida</title>
        <meta name='description' content='Iglesia Cristiana Evangélica Encuentro de Vida' />
        <meta name="keywords" content="iglesia, cristiana, evangelica, encuentro, vida, flores, alberdi, caba, buenos aires" />
      </Helmet>
      <ResponsiveAppBar />
      <div className='App-body'>{children}</div>
    </div>
  );
}

export default PageWrapper;
