import { Helmet } from 'react-helmet';
import ResponsiveAppBar from '../components/ResponsiveAppBar';

export default function Map() {
  return (
    <div
      style={{
        backgroundColor: '#282c34',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 'calc(10px + 2vmin)',
        color: 'white',
        overflow: 'hidden',

      }}
    >
      <Helmet>
        <title>Cómo llegar a Iglesia Encuentro de Vida</title>
        <meta name="description" content="Cómo llegar a Iglesia Encuentro de Vida" />
        <meta name="keywords" content="cómo llegar, iglesia, encuentro, vida, flores, asamblea de dios, capital, cristiana" />
      </Helmet>
      <ResponsiveAppBar />
      <iframe
        src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3282.8453433144323!2d-58.47148358421396!3d-34.63334838045182!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcc985e52200b5%3A0xb792b3978b22018!2sAv.%20Juan%20Bautista%20Alberdi%202941%2C%20C1406%20GSF%2C%20Buenos%20Aires!5e0!3m2!1ses-419!2sar!4v1646708510364!5m2!1ses-419!2sar'
        style={{
          height: 'calc(100vh - 73px)',
          marginTop: '68.5px',
          minWidth: '100%'
        }}
        allowFullScreen=''
        loading='lazy'
      ></iframe>
    </div>
  );
}
