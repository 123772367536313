import { Helmet } from 'react-helmet';
import ResponsiveAppBar from '../../components/ResponsiveAppBar';
import { Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { Download } from '@mui/icons-material';

export default function Reserva() {
  return (
    <div
      style={{
        backgroundColor: '#282c34',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 'calc(10px + 2vmin)',
        color: 'white',
        overflow: 'hidden',
      }}
    >
      <Helmet>
        <title>Reserva</title>
        <meta
          name='description'
          content='Reserva quinta de flores'
        />
        <meta
          name='keywords'
          content='cómo llegar, iglesia, encuentro, vida, flores, asamblea de dios, capital, cristiana'
        />
      </Helmet>
      <ResponsiveAppBar />

      <div
        style={{
          marginTop: '100px',
          minWidth: '50%',
          maxWidth: '80%',
        }}
      >
        <Typography variant='h4'>
          {' '}
          Reserva de la Quinta sita en Azara 2600 - Merlo{' '}
        </Typography>

        <ul
          style={{
            textAlign: 'left',
            paddingLeft: '12px',
            gap: '10px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <p>
            Apreciados hermanos: Les informamos a continuación, los pasos a
            seguir para el alquiler de la quinta a partir del 1 de diciembre
            2022
          </p>
          <p>
            Las reservas deben realizarse a los teléfonos 4611-8145 u 11 6045
            7728 en el horario de 9 a 14 hs, abonando una seña correspondiente
            al 50% del valor total de los concurrentes, tenga en cuenta que la
            reserva quedará firme SOLO ante el abono de la misma.
          </p>
          <p>
            Con cinco días hábiles de anticipación, enviarán por correo
            electrónico a quintaedv@gmail.com, un listado de las personas que
            asistirán a la quinta, la que será enviada a la Cía de Seguro
            contratada para el predio; por tal motivo, al ingresar, se
            verificará que la persona figure en la lista enviada
            anticipadamente, de no existir, SIN EXCEPCION, no podrá ingresar. En
            la misma debe indicarse:
          </p>
          <ul>
            <li>Nombre y Apellido</li>
            <li>DNI</li>
            <li>Mención si se transportará en auto o micro.</li>
            <li>
              Datos del micro
              <ul>
                <li>Chofer (Nombre, apellido, DNI)</li>
                <li>Habilitación</li>
                <li>Patente</li>
              </ul>
            </li>
          </ul>
          <p>Asimismo, se adjuntan las <a href='/quinta-normas' style={{ color: 'white'}}>Normas</a> que
          deben respetarse, durante la permanencia en la quinta Agradecemos su
          disposición para dar cumplimiento a todo lo mencionado. Dios le
          bendiga </p>
        </ul>
      </div>

      <Link to={'/reserva.pdf'} download target='_blank'>  
        <Button variant="outlined" startIcon={<Download />}  style={{
          marginBottom: 50,
          color: 'white',
          borderColor: 'white'
        }}>
          Descargar
        </Button>
      </Link>
    </div>
  );
}
