import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Routes, Navigate, BrowserRouter } from 'react-router-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Map from './views/Map';
import Radio from './views/Radio';
import Normas from './views/quinta/Normas';
import Reserva from './views/quinta/Reserva';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/como-llegar" element={<Map />} />
        <Route path="/radio" element={<Radio/>} />
        <Route path="/quinta-normas" element={<Normas/>}></Route>
        <Route path="/quinta-reserva" element={<Reserva/>}></Route>
        <Route path="/quinta" element={<Navigate replace to ="/quinta-reserva"/>}></Route>
        <Route path="/" element={<Navigate replace to="/radio" />} />
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
