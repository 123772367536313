import React, { useEffect } from 'react';
import useAudioPlayer from '../components/useAudioPlayer';
import { Pause, PlayArrow } from '@mui/icons-material';
import { IconButton, Typography } from '@mui/material';

import AudioSpectrum from 'react-audio-spectrum2';
import behind_logo_1 from '../assets/behind_logo_1.png';
import behind_logo_2 from '../assets/behind_logo_2.png';
import behind_logo_3 from '../assets/behind_logo_3.png';
import logo from '../assets/logo.png';
import PageWrapper from '../PageWrapper';
import { Helmet } from 'react-helmet';

export default function Radio() {
  const { isPlaying, play, stop, songname, artist, audioElement } =
    useAudioPlayer({
      audioSource: 'https://adfradio.com.ar/radio/8000/live',
      metadataSource: 'wss://adfradio.com.ar/api/live/nowplaying/adfradio',
    });

  useEffect(() => {
    return () => {
      stop();
    };
  }, []);

  return (
    <PageWrapper>
      <Helmet>
        <title>ADF Radio</title>
        <meta name="description" content="Radio Cristiana de Iglesia Asamblea de Dios Flores Encuentro de Vida" />
        <meta name="keywords" content="radio, cristiana, iglesia, asamblea, dios, flores, encuentro, vida" />
      </Helmet>
      <div
        style={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '40vmin',
        }}
      >
        <img
          style={{
            position: 'absolute',
            bottom: '0',
          }}
          src={behind_logo_2}
          className='BehindLogo2'
          alt='logo'
        />
        <img
          style={{
            position: 'absolute',
            bottom: '0',
          }}
          src={behind_logo_1}
          className='BehindLogo1'
          alt='logo'
        />
        <img
          style={{
            position: 'absolute',
            bottom: '0',
          }}
          src={behind_logo_3}
          className='BehindLogo3'
          alt='logo'
        />
        <img
          style={{
            position: 'absolute',
            bottom: '0',
          }}
          src={logo}
          className='Logo'
          alt='logo'
        />
      </div>

      <Typography variant='h4'>{songname}</Typography>
      <Typography variant='h6'>{artist}</Typography>
      <IconButton
        onClick={async () => {
          if (isPlaying) {
            await stop();
          } else {
            await play();
          }
        }}
      >
        {isPlaying ? (
          <Pause
            style={{
              color: 'white',
              transition: 'all 0.3s ease-in',
              cursor: 'pointer',
              width: '50px',
              height: '50px',
              borderRadius: '50%',
            }}
          />
        ) : (
          <PlayArrow
            style={{
              color: 'white',
              transition: 'all 0.3s ease-in',
              cursor: 'pointer',
              width: '50px',
              height: '50px',
              borderRadius: '50%',
            }}
          />
        )}
      </IconButton>
      <AudioSpectrum
        style={{
          width: '100%',
          position: 'fixed',
          bottom: 0,
          pointerEvents: 'none',
        }}
        audioEle={audioElement}
        width={
          window.innerWidth > window.innerHeight
            ? window.innerWidth
            : window.innerHeight
        }
        height={innerHeight / 3}
        capColor={'red'}
        capHeight={2}
        meterWidth={2}
        meterCount={512}
        meterColor={[
          { stop: 0, color: '#f00' },
          { stop: 0.5, color: '#0cd7fd' },
          { stop: 1, color: 'red' },
        ]}
        gap={5}
      />
    </PageWrapper>
  );
}
