import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import logo_without_background from '../assets/logo_without_background.png';

const pages = [
  // { name: 'Inicio', url: '/home' },
  { name: 'Radio', url: '/radio' },
  // { name: 'En qué creemos', url: '/credo' },
  { name: 'Cómo Llegar', url: '/como-llegar' },
  // { name: 'Contacto', url: '/contacto' },
];

const menuPages = [
  {
    name: 'Quinta',
    pages: [
      {
        name: 'Normas',
        url: '/quinta-normas',
      },
      {
        name: 'Reserva',
        url: '/quinta-reserva',
      },
    ],
  },
];

const ResponsiveAppBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const Logo = () => (
    <img
      src={logo_without_background}
      alt='logo'
      style={{
        height: '2.5rem',
        cursor: 'pointer',
      }}
      onClick={() => {
        console.log('Todo');
      }}
    />
  );

  return (
    <AppBar
      position='fixed'
      style={{
        backgroundColor: '#282c34',
      }}
    >
      <Container maxWidth='xl'>
        <Toolbar disableGutters>
          <Box sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}>
            <Logo />
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size='large'
              aria-label='menu'
              aria-controls='menu-appbar'
              aria-haspopup='true'
              onClick={handleOpenNavMenu}
              color='inherit'
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id='menu-appbar'
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <NavLink
                  key={page.name}
                  to={page.url}
                  style={{ textDecoration: 'none', color: 'black' }}
                >
                  <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                    <Typography textAlign='center'>{page.name}</Typography>
                  </MenuItem>
                </NavLink>
              ))}

              {menuPages.map((menu) => (
                <>
                  <Button
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup='true'
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    style={{ textDecoration: 'none', color: 'black', padding: '6px 16px' }}
                  >
                    {menu.name}
                  </Button>
                  <Menu
                    id='basic-menu'
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                    style={{ textDecoration: 'none' }}
                  >
                    {menu.pages.map((child, index) => (
                      <NavLink
                        key={index}
                        to={child.url}
                        style={{ textDecoration: 'none', color: 'black' }}
                      >
                        <MenuItem onClick={handleClose}>{child.name}</MenuItem>
                      </NavLink>
                    ))}
                  </Menu>
                </>
              ))}
            </Menu>
          </Box>
          <Box
            sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
            style={{
              justifyContent: 'flex-end',
            }}
          >
            <Logo />
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <NavLink
                key={page.name}
                to={page.url}
                style={{ textDecoration: 'none', color: 'black' }}
              >
                <Button
                  key={page.name}
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: 'white', display: 'block' }}
                >
                  {page.name}
                </Button>
              </NavLink>
            ))}
            {menuPages.map((menu) => (
              <>
                <Button
                  id='basic-button'
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup='true'
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                  style={{ textDecoration: 'none', color: 'white' }}
                  
                >
                  {menu.name}
                </Button>
                <Menu
                  id='basic-menu'
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                  style={{ textDecoration: 'none' }}
                >
                  {menu.pages.map((child, index) => (
                    <NavLink
                      key={index}
                      to={child.url}
                      style={{ textDecoration: 'none', color: 'black' }}
                    >
                      <MenuItem onClick={handleClose}>{child.name}</MenuItem>
                    </NavLink>
                  ))}
                </Menu>
              </>
            ))}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
