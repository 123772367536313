import { Helmet } from 'react-helmet';
import ResponsiveAppBar from '../../components/ResponsiveAppBar';
import { Button, Typography } from '@mui/material';
import { Download } from '@mui/icons-material';
import { Link } from 'react-router-dom';


export default function Normas() {
  return (
    <div
      style={{
        backgroundColor: '#282c34',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 'calc(10px + 2vmin)',
        color: 'white',
        overflow: 'hidden',
      }}
    >
      <Helmet>
        <title>Normas</title>
        <meta
          name='description'
          content='Normas Quinta de Flores'
        />
        <meta
          name='keywords'
          content='quinta, iglesia, encuentro, vida, flores, asamblea de dios, capital, cristiana'
        />
      </Helmet>
      <ResponsiveAppBar />

      <div
        style={{
          marginTop: '100px',
          minWidth: '50%',
          maxWidth: '80%',
        }}
      >
        <Typography
          variant='h4'
        > Normas de permanencia en la Quinta </Typography>

        <ul
          style={{
            
            textAlign: 'left',
            paddingLeft:'12px',
            gap: '10px',
            display: 'flex',
            flexDirection: 'column',

          }}
        >
          <li>
            {' '}
            El cuidado y supervisión de los menores es responsabilidad de sus
            padres o a quienes ellos hayan delegado.{' '}
          </li>
          <li>
            {' '}
            Los vehículos deben permanecer en la playa de estacionamiento, junto
            a la entrada del parque. Única excepción es para ingresar y retirar
            personas con discapacidad física.
          </li>
          <li> No está permitido ingresar mascotas.</li>
          <li>
            {' '}
            Compartamos las instalaciones. No las dañemos. No cambiemos de lugar
            los implementos sin solicitar permiso. Démosle el uso adecuado. Está
            prohibido hacer inscripciones.
          </li>
          <li>
            {' '}
            El uso de audio se autorizará, sin que el volumen interfiera en las
            actividades de los demás, en caso de que estén compartiendo la
            quinta con otros contingentes.
          </li>

          <li>
            {' '}
            Mantengamos el orden y la limpieza. Arrojemos los residuos a los
            recipientes destinados a tal efecto.{' '}
          </li>
          <li>
            {' '}
            En el predio hay una pileta cercada. Está prohibido cruzar el cerco
            de la misma, en horarios que no cuenta con la presencia de nuestra
            guardavidas. Su ingreso y permanencia en ella, debe realizarse bajo
            el cumplimiento de las normas establecidas en la entrada a la misma{' '}
          </li>
          <li>
            {' '}
            Cuidemos la flora: no dañemos los árboles, no cortemos ramas ni
            flores.{' '}
          </li>
          <li>
            {' '}
            Actuemos con los demás como deseamos que los demás actúen con
            nosotros. Respetemos las áreas privadas.
          </li>
          <li>
            {' '}
            Hablemos siempre con educación y respeto. Usemos vestimenta
            decorosa. Que su comportamiento tenga el suficiente pudor y tacto
            para que los demás no se sientan molestos.{' '}
          </li>
          <li>
            {' '}
            Practiquemos hábitos de vida que favorezcan la salud. No está
            permitido fumar en espacios cubiertos, consumir drogas y/o siendo
            menores de 18 años, consumir alcohol. En mayores de edad, la medida
            de bebida alcohólica permitida tomar, es igual a la permitida para
            manejar.{' '}
          </li>
          <li>
            {' '}
            Juguemos a la pelota en las canchas. No peloteemos en el área de
            picnic.
          </li>
        </ul>

        <p>
          Las normas aquí escritas son enunciativas y no excluyen a aquellas que
          corresponden a las buenas costumbres y el sentido común.{' '}
        </p>
        <p>
          La Administración declina toda responsabilidad eventual en el caso de
          robo, accidente, daños o demás bienes o personas. Asimismo no responde
          por daños causados por tormentas, caída de ramas, granizo, cosas de
          fuerza mayor, etc., por ser un ambiente predominantemente natural, con
          sus riesgos inherentes.{' '}
        </p>

        <p>
          La Administración se reserva el derecho de admisión y/o permanencia,
          sin tener que dar explicaciones.{' '}
        </p>
        <p> Les deseamos una muy linda estadía. </p>
        <p>¡Dios los bendiga!! </p>
      </div>

      <Link to={'/normas.pdf'} download target='_blank'>  
        <Button variant="outlined" startIcon={<Download />} style={{
          marginBottom: 50,
          color: 'white',
          borderColor: 'white'
        }}>
          Descargar
        </Button>
      </Link>
    </div>
  );
}
