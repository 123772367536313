import { useEffect, useRef } from 'react';
import { useState } from 'react';
import NchanSubscriber from 'nchan';

export default function useAudioPlayer({ audioSource, metadataSource }){

  const audioRef = useRef(new Audio());

  const [isPlaying, setIsPlaying] = useState(false);
  const [songname, setSongname] = useState('ADF Radio');
  const [artist, setArtist] = useState('Encuentro de Vida');
  const [isLive, setIsLive] = useState(false);
  const [photoURL, setPhotoURL] = useState('');
  const [metadataSourceConnected, setMetadataSourceConnected] = useState(false);

  const audioElement = audioRef.current;
  audioElement.crossOrigin = 'anonymous';
  

  const sub = new NchanSubscriber(metadataSource);

  const parseMetadata = (message) => {
    const {now_playing: { song }, live} = JSON.parse(message);

    const {is_live: isLive} = live;
    const {title: songname, artist, art: photoURL} = song;

    setSongname(songname ?? 'ADF Radio');
    setArtist(artist ?? 'Encuentro de Vida');
    setPhotoURL(photoURL);
    setIsLive(isLive);
  };
  sub.on('message', parseMetadata);
  sub.on('connect', () => setMetadataSourceConnected(true));
  sub.on('disconnect', () => setMetadataSourceConnected(false));
  sub.on('error', err => console.log('Error from metadata source', err));


  useEffect(() => {

    if(metadataSourceConnected) return;
    sub.start();

    return () => {
      if(metadataSourceConnected) sub.stop();
    };
  });

  const play = async () => {
    if(audioElement.isPlaying) return;
    try{
      audioElement.src = audioSource;
      await audioElement.play();
      setIsPlaying(true);
    } catch(err){
      console.log('Error playing audio', err);
      await play();
    }
  };

  const stop = async () => {
    try{
      audioElement.pause();
      audioElement.src = '';
      setIsPlaying(false);
    } catch(err){
      Promise.reject(err);
    }
  };

  return {isPlaying, play, stop, isLive, songname, artist, photoURL, audioElement};
}